@layer reset, base, tokens, recipes, utilities;

html {
	font-family: var(--font-vontobel-sans);
	/* All tap clicks on mobile have to be transparent */
	-webkit-tap-highlight-color: transparent;
	font-size: 17px;
	font-weight: var(--font-weights-regular);
	letter-spacing: -0.015rem;
	text-wrap: wrap;
}

body {
	min-height: 100vh;
}

main {
	overflow: hidden;
}

@supports (overflow: clip) {
	main {
		overflow: clip;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	text-wrap: wrap;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
.vontobel-scrollbar {
	scrollbar-width: none;
	scrollbar-color: var(--colors-secondary-nucleus-blue-60) transparent;
}

/* Chrome, Edge, and Safari */
.vontobel-scrollbar::-webkit-scrollbar {
	width: 8px;
}

ol.breadcrumbs {
	margin-left: 0;
}

.vontobel-scrollbar::-webkit-scrollbar-track {
	/* background: #972b2b; */
	background: transparent;
	box-shadow: inset 0 0 5px var(--colors-base-neutrals-40);
	border-left: 2.5px solid transparent;
	border-right: 2.5px solid transparent;
}

.vontobel-scrollbar::-webkit-scrollbar-thumb {
	background-color: var(--colors-secondary-nucleus-blue-60);
	border-radius: 7px;
	border: 0px none white;
}

@media screen {
	:root {
		--layout-margin: 24px;
		--layout-columns: 4;
		--layout-gutter: 16px;
	}
}

@media screen and (min-width: 768px) {
	:root {
		--layout-margin: 32px;
		--layout-columns: 8;
		--layout-gutter: 16px;
	}
}

@media screen and (min-width: 1024px) {
	:root {
		--layout-margin: 32px;
		--layout-columns: 12;
		--layout-gutter: 16px;
	}
}
@media screen and (min-width: 1280px) {
	:root {
		--layout-margin: 32px;
		--layout-columns: 12;
		--layout-gutter: 32px;
	}
}

@media print {
	:root {
		--layout-margin: 32px;
		--layout-columns: 12;
		--layout-gutter: 16px;
	}
	html {
		font-size: 10px;
	}
}

:root {
	/* header height will be overriden */
	--layout-capped-screen-width: min(100vw, 1280px);
	--layout-total-margin: calc(var(--layout-margin) * 2);
	--layout-total-gutter: calc((var(--layout-gutter) * (var(--layout-columns) - 1)));
	--layout-total-column-width: calc(
		var(--layout-capped-screen-width) - var(--layout-total-gutter) - var(--layout-total-margin)
	);
	--column-width: calc((var(--layout-capped-screen-width) - var(--layout-total-column-width)) / var(--layout-columns));
	--column-width: calc(var(--layout-total-column-width) / var(--layout-columns));
}

:target {
	scroll-margin-top: var(--header-height);
}

/* TINY MC CSS */

.tiny h2 {
	font-size: 80px;
}
.tiny h3 {
	font-size: 60px;
}
.tiny h4 {
	font-size: 45px;
}
.tiny h5 {
	font-size: 32px;
}
.tiny h6 {
	font-size: 24px;
}
.tiny p {
	font-size: 21px;
}

.tiny em {
	font-style: italic;
}

.tiny b {
	font-weight: bold;
}
